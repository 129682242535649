.i18n {
  height: 1px;
  overflow: hidden;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  padding: 4rem 0;
}

/* Section container for Minting widget */
.demo {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 93%;
  max-width: 29em;
  gap: 0.5rem;
}

.widget {
  position: relative;
}

/* Targeting the Minting widget */
.widget > [class*="WidgetWrapper"] {
  border: 1px solid #d4d9ec;
  box-shadow: none;
  width: 100%;
  padding-bottom: 60px;
}

/* Targeting the action button text */
[class*="ActionButton"] div {
  color: #0f121a;
}

/* Targeting the 'Select Token' button */
[class*="TokenButton"] div,
[class*="TokenButton"] svg {
  color: #0f121a;
  stroke: #0f121a;
}

.connectors {
  align-self: flex-start;
  border-radius: 1em;
}

@keyframes spotlight {
  10% {
    background-color: #e2e3e9;
  }
  20% {
    background-color: #e2e3e9;
  }
}

.connectors:focus {
  animation: 2.5s ease-out 1 spotlight;
}

.rule {
  border: 1px solid #c3c5cb;
  margin: 0;
  width: calc(100% - 2rem);
  max-width: 664px;
}

@media (max-width: 727px) {
  .connectors {
    align-self: center;
  }
}
